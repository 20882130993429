import { SelectedSubscriptionPlanOption } from '../pages/crm-tools/customers/get-customer-details-slice'
export const MICROSOFT_NAMESPACE = 'http://schemas.microsoft.com/ws/2008/06/identity/claims'

export const MIME_TYPES: Record<string, string> = {
  csv: 'text/csv'
}

export const FIRST_NAME_MAX_LENGTH = 110
export const LAST_NAME_MAX_LENGTH = 110
export const EMAIL_ADDRESS_MAX_LENGTH = 79 // Auth0 maximum email address characters allowed

// All possible subscription plan options
export const SUBSCRIPTION_PLAN_OPTIONS: SelectedSubscriptionPlanOption[] = ['All', 'Subscription', 'Trial', 'PAYG']
