import FlagRoundedIcon from '@mui/icons-material/FlagRounded'
import { Chip, IconButton, Popper } from '@mui/material'
import React, { useState } from 'react'

export const formatCustomerName = (row: Record<string, any>, customerNameKey?: string, customerSubscriptionPlanKey?: string) => {
  return <CustomerNameComponent row={row} customerNameKey={customerNameKey ?? 'customer'} customerSubscriptionPlanKey={customerSubscriptionPlanKey ?? 'customerSubscriptionPlan'} />
}

interface CustomerNameComponentProps {
  row: Record<string, any>,
  customerNameKey: string,
  customerSubscriptionPlanKey: string
}

const CustomerNameComponent = ({ row, ...props }: CustomerNameComponentProps) => {
  const [showPopper, setShowPopper] = useState(false)
  const [popperAnchor, setPopperAnchor] = useState<null | HTMLElement>(null)

  const handleOnMouseEnter = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setPopperAnchor(event.currentTarget)
    setShowPopper(true)
  }

  const handleOnMouseLeave = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowPopper(false)
    setPopperAnchor(null)
  }

  if (row[props.customerSubscriptionPlanKey] === 'Trial') {
    return <>
      <div style={{ display: 'flex', alignItems: 'center', height: '1.3rem' }}>
      { row[props.customerNameKey] }
          <IconButton
            sx={{
              cursor: 'default',
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          >
            <FlagRoundedIcon sx={{ fill: '#F79009', transform: 'translateY(-2px)' }} />
          </IconButton>
      </div>
      <Popper placement="right" open={showPopper} anchorEl={popperAnchor}>
        <Chip label="Trial Customer" sx={{ backgroundColor: '#F79009', color: '#FFF' }} />
      </Popper>
    </>
  }

  return <>{ row[props.customerNameKey] }</>
}
