import React from 'react'
import { Autocomplete, TextField } from '@mui/material'

export interface TableDropdownProps<T> {
  options: readonly T[];
  value: T;
  onChange: (value: T) => void;
  placeholder?: string;
  getOptionLabel?: (option: T) => string;
}

const dropdownStyle = {
  width: 200,
  marginRight: 2,
  '& .MuiOutlinedInput-root': {
    padding: 0,
    '.MuiOutlinedInput-notchedOutline': {
      borderTop: 'none',
      borderRight: 'none',
      borderLeft: 'none',
      borderRadius: 0
    },
    '.MuiAutocomplete-endAdornment': {
      top: '8px'
    },
    '.MuiAutocomplete-input': {
      paddingBottom: '5px'
    }
  }
}

export function TableDropdown<T> ({
  options,
  value,
  onChange,
  placeholder = 'Select Option',
  getOptionLabel
}: TableDropdownProps<T>): React.ReactElement {
  return (
    <Autocomplete
      disableClearable
      options={options as any}
      value={value as any}
      onChange={(_, newValue) => onChange(newValue as T)}
      getOptionLabel={getOptionLabel as any}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
        />
      )}
      sx={dropdownStyle}
    />
  )
}
