import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingType } from '../../../utils/types'
import axios from 'axios'
import { AppDispatch, RootState } from '../../../store/store'
import { CustomerStatusOptions, SelectedSubscriptionPlanOption, SubscriptionPlan } from './get-customer-details-slice'

interface GetCustomersState {
  items: Customer[]
  pageSize: number
  pageNumber: number
  totalCount: number
  searchText: string
  selectedSubscriptionPlanOption: SelectedSubscriptionPlanOption
  selectedStatus: string
  loading: LoadingType
  error: string
}

export interface Customer {
  id: number
  name: string
  joinedDate: string
  status: CustomerStatusOptions
  subscriptionPlan: SubscriptionPlan
  totalAssessmentAllocation: number
  totalAssessmentCount: number
  teamMembersCount: number
  lastAssessmentOrderedDate: string
  nextRenewDate: string
}

export interface GetCustomers {
  items: Customer[]
  pageNumber: number
  totalCount: number
}

export const DEFAULT_PAGE_SIZE = 10

const initialState: GetCustomersState = {
  items: [],
  pageSize: DEFAULT_PAGE_SIZE,
  pageNumber: 1,
  totalCount: 0,
  searchText: '',
  selectedSubscriptionPlanOption: 'All',
  selectedStatus: 'all',
  loading: 'idle',
  error: ''
}

export const getCustomers = createAsyncThunk<
  GetCustomers,
  void,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('customers/getCustomers', async (_request, thunkAPI) => {
  const { tokenState, getCustomersState } = thunkAPI.getState()
  const params = new URLSearchParams()
  const { pageNumber, pageSize, searchText, selectedStatus, selectedSubscriptionPlanOption } = getCustomersState

  params.append('pageNumber', pageNumber.toString())
  params.append('pageSize', pageSize.toString())
  params.append('search', searchText)

  if (selectedSubscriptionPlanOption !== 'All') {
    params.append('subscriptionPlan', selectedSubscriptionPlanOption)
  }

  if (selectedStatus) {
    params.append('status', selectedStatus)
  }

  try {
    const response = await axios.get<GetCustomers>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/customers?${params.toString()}`,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  }
})

export const getCustomersSlice = createSlice({
  name: 'get-customers',
  initialState,
  reducers: {
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
      state.pageNumber = 1
      state.loading = 'idle'
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload
      state.pageNumber = 1
      state.loading = 'idle'
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload
      state.loading = 'idle'
    },
    setSubscriptionPlanFilter: (state, action: PayloadAction<SelectedSubscriptionPlanOption>) => {
      state.selectedSubscriptionPlanOption = action.payload
      state.pageNumber = 1
      state.loading = 'idle'
    },
    setActiveTab: (state, action: PayloadAction<string>) => {
      state.selectedStatus = action.payload
      state.pageNumber = 1
      state.loading = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomers.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(getCustomers.fulfilled, (state, { payload }) => {
      state.items = payload.items
      state.pageNumber = payload.pageNumber
      state.totalCount = payload.totalCount
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(getCustomers.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export const { setSearchText, setPageSize, setPageNumber, setActiveTab, setSubscriptionPlanFilter } = getCustomersSlice.actions
