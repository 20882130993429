import React from 'react'
import { Grid, IconButton, Stack, Typography } from '@mui/material'
import { TableSearch, TableSearchProps } from './table-search'
import { Download } from '@mui/icons-material'

export interface TableHeaderProps extends TableSearchProps {
  title?: string
  description?: string,
  onExportButtonClick?: () => void,
  subscriptionPlanElement?: React.ReactNode
}

export const TableHeader = ({
  title, description, onExportButtonClick, searchValue,
  onSearchClick, onResetSearchClick, onSearchTextChange,
  subscriptionPlanElement
}: TableHeaderProps) =>
  <Grid container>
    {!!title && <Grid item xs>
      <Stack spacing={1.5}>
        <Typography variant='h3'>{title}</Typography>
        {!!description && <Typography color={({ palette }) => palette.text.secondary}>{description}</Typography>}
      </Stack>
    </Grid>}
    <Grid container item xs justifyContent="flex-end" alignItems="flex-end">
      { !!onExportButtonClick && <IconButton sx={{ marginRight: 2, borderRadius: 0, backgroundColor: (theme) => theme.palette.grey[300] }} color="primary" onClick={onExportButtonClick}><Download /></IconButton> }

      {subscriptionPlanElement}

      <TableSearch searchValue={searchValue} onSearchClick={onSearchClick} onResetSearchClick={onResetSearchClick} onSearchTextChange={onSearchTextChange} />
    </Grid>
  </Grid>
