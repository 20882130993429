import { useEffect, useState } from 'react'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { getSummary } from './get-summary-slice'
import { formatDuration } from '../../utils/functions'
import { AnilityBackdrop } from '../../components/anility-backdrop'
import { AnilityScopes } from '../../interfaces/anility-scopes'
import { usePermission } from '../../hooks/use-permission'
import { TimeInfo } from './components/time-info'
import { SummaryCard, SummaryCardProps } from '../../components/summary-card'
import CreateAssessmentDialogSuperForm from './report-workspace/create-assessment-dialog-super-form'
import { getReportWorkspaceSuperFormOptions } from './report-workspace/get-report-workspace-super-form-options'
import { useSignalR } from '../../signalr/signalr-context'

const Summary = () => {
  const dispatch = useAppDispatch()
  const { getSummaryState, getReportWorkspaceSuperFormOptionsState, createReportWorkspaceState } = useAppSelector((state) => state)
  const { summary, loading } = getSummaryState
  const [showCreateAssessmentSuperForm, setShowCreateAssessmentDialogSuperForm] = useState(false)
  const [hasWriteReportsPermission] = usePermission([
    AnilityScopes.Write.Reports
  ])
  const { hubConnection } = useSignalR()

  const usageSummaryCards: SummaryCardProps[] = [
    { title: 'Assessments Ordered', value: summary?.assessmentsCount ?? 0, description: 'Total number of assessments ordered' },
    { title: 'Customers', value: summary?.customersCount ?? 0, description: 'Total number of customers' },
    { title: 'Entities Assessed', value: summary?.entityAssessedCount ?? 0, description: 'Total number of entities assessed' },
    { title: '# of Analyst', value: summary?.analystsCount ?? 0, description: 'Total number of analysts' }
  ]

  const assessmentsSummaryCards: SummaryCardProps[] = [
    { title: 'Completed', value: summary?.assessmentsCompletedCount ?? 0, description: 'Successfully completed assessments' },
    { title: 'Ordered', value: summary?.assessmentsOrderedCount ?? 0, description: 'Awaiting for entity assessed to respond' },
    { title: 'In Progress', value: summary?.assessmentsInProgressCount ?? 0, description: 'Ready for assessment to be created' },
    { title: 'Declined', value: summary?.assessmentsDeclinedCount ?? 0, description: 'Entity assessed has declined or invite timed out' }
  ]

  const timeSummaryCards: SummaryCardProps[] = [
    { title: 'Avg Time For Entity\nAssessed To Respond', value: formatDuration(summary?.entityAssessedRespondAvgTimeMs ?? 0), description: 'Once customer has created assessment request', valueVariant: 'h3' },
    { title: 'Avg Time To Create\nAssessment', value: formatDuration(summary?.createAssessmentAvgTimeMs ?? 0), description: 'Once information has been received', valueVariant: 'h3' },
    { title: 'Avg Time To Deliver\nAssessment To Customer', value: formatDuration(summary?.deliverAssessmentAvgTimeMs ?? 0), description: 'Time from customer ordering report to receiving', valueVariant: 'h3' },
    { title: 'Live Support Tickets', value: '-', description: 'Outstanding support or service requests', valueVariant: 'h3' }
  ]

  useEffect(() => {
    if (loading === 'idle') {
      dispatch(getSummary())
    }
  }, [loading])

  useEffect(() => {
    if (!hubConnection) { return }

    hubConnection.on('ReloadSummary', () => {
      dispatch(getSummary())
    })

    return () => {
      hubConnection.off('ReloadSummary')
    }
  }, [hubConnection])

  const handleCreateAssessmentClickSuperForm = () => {
    dispatch(getReportWorkspaceSuperFormOptions())
    setShowCreateAssessmentDialogSuperForm(true)
  }

  const handleCloseSuperForm = () => {
    setShowCreateAssessmentDialogSuperForm(false)
  }

  return (
    <>
      <Box p={2} sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative',
        rowGap: 2
      }}>
        <Box pt={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Stack spacing={1.5}>
            <Typography variant='h3'>Summary</Typography>
            <Typography color={({ palette }) => palette.text.secondary}>See below insights related to assessments</Typography>
          </Stack>
          {hasWriteReportsPermission &&
            <Stack spacing={1.5}>
              <Button
                id="create-assessment-button"
                variant="contained" type="button" color="primary" onClick={handleCreateAssessmentClickSuperForm}
              >Create New Assessment +</Button>
            </Stack>
          }
        </Box>
        <Box>
          <Typography variant='h4' mb={2}>Usage</Typography>
          <Grid container rowGap={1} columnGap={1} justifyContent="space-between" marginLeft={0}>
            {usageSummaryCards.map((sc) => <SummaryCard key={sc.title} {...sc} />)}
          </Grid>
        </Box>
        <Box>
          <Typography variant='h4' mb={2}>Assessments</Typography>
          <Grid container rowGap={1} columnGap={1} justifyContent="space-between" marginLeft={0}>
            {assessmentsSummaryCards.map((sc) => <SummaryCard key={sc.title} {...sc} />)}
          </Grid>
        </Box>
        <Box>
          <Typography variant='h4' mb={2}>Time</Typography>
          <Grid container rowGap={1} columnGap={1} justifyContent="space-between" marginLeft={0} mb={2}>
            {timeSummaryCards.map((sc) => <SummaryCard key={sc.title} {...sc} />)}
          </Grid>
          <TimeInfo />
        </Box>
      </Box >
      <CreateAssessmentDialogSuperForm onClose={handleCloseSuperForm} show={showCreateAssessmentSuperForm} />
      <AnilityBackdrop open={getReportWorkspaceSuperFormOptionsState.loading === 'loading' || createReportWorkspaceState.loading === 'loading'} />
    </>
  )
}

export default Summary
