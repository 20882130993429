import React, { ChangeEvent, MouseEvent, useEffect, useState, useCallback, useMemo } from 'react'
import { Box, Grid, Stack, Tab, Tabs, Theme, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { AnilityBackdrop } from '../../../components/anility-backdrop'
import { Customer, getCustomers, setActiveTab, setPageNumber, setPageSize, setSearchText, setSubscriptionPlanFilter } from './get-customers-slice'
import PaginatedTable, { Column } from '../../../components/paginated-table'
import { TablePagination, TablePaginationProps } from '../../../components/table-pagination'
import { TableHeader } from '../../../components/table-header'
import { formatDate, secureFileDownload } from '../../../utils/functions'
import { formatCustomerName } from '../../../components/customer-name-component'
import { ProgressBarCell } from '../../../components/progress-bar-cell'
import { TableDropdown } from '../../../components/table-dropdown'
import { SUBSCRIPTION_PLAN_OPTIONS } from '../../../utils/constants'
import { SelectedSubscriptionPlanOption } from './get-customer-details-slice'
import { Colors } from '../../../theme/anility-dark-theme'

const calculateUsagePercentage = (count: number, total: number) => {
  if (count >= total) return 100
  return Math.round((count * 100) / total)
}

const UsageCell = ({ count, total }: { count: number; total: number }) => {
  const theme = useTheme()
  const percentage = calculateUsagePercentage(count, total)

  const getUsageTextColor = (percentage: number, theme: Theme) => {
    if (percentage >= 100) {
      return theme.palette.error.main
    }
    if (percentage >= 90) {
      return Colors.orange
    }
    return theme.palette.text.primary
  }

  const textColor = getUsageTextColor(percentage, theme)

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Box sx={{ flexGrow: 1 }}>
        <ProgressBarCell
          row={{ responseProgressPercentage: percentage }}
          progressKey="responseProgressPercentage"
        />
      </Box>
      <Box sx={{ minWidth: 45, color: textColor }}>
        {`${count}/${total}`}
      </Box>
    </Box>
  )
}

const LastAssessmentCell = ({ date }: { date: string }) => {
  const lastOrderDate = new Date(date)
  const threeMonthsAgo = new Date()
  const theme = useTheme()

  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3)

  return (
    <Typography
      fontSize='0.8rem'
      color={lastOrderDate < threeMonthsAgo ? theme.palette.error.main : 'inherit'}
    >
      {formatDate(date)}
    </Typography>
  )
}

const columns: Column[] = [
  {
    id: 'name',
    label: 'Customer Name',
    minWidth: 200,
    align: 'left',
    format: (row) => formatCustomerName(row, 'name', 'subscriptionPlan')
  },
  {
    id: 'status',
    label: 'Status',
    width: 100,
    align: 'left'
  },
  {
    id: 'subscriptionPlan',
    label: 'Subscription Plan',
    width: 150,
    align: 'left',
    format: (row) => row.subscriptionPlan === 'PAYG' ? 'PAYG' : row.subscriptionPlan
  },
  {
    id: 'usage',
    label: 'Usage',
    width: 250,
    align: 'left',
    format: (row) => (
      <UsageCell
        count={row.totalAssessmentCount}
        total={row.totalAssessmentAllocation}
      />
    )
  },
  {
    id: 'users',
    label: 'Users',
    width: 80,
    align: 'left',
    format: (row) => row.teamMembersCount
  },
  {
    id: 'lastAssessmentOrdered',
    label: 'Last Assessment',
    width: 180,
    align: 'left',
    format: (row) => (
      <LastAssessmentCell date={row.lastAssessmentOrderedDate} />
    )
  },
  {
    id: 'renewalDate',
    label: 'Renewal Date',
    width: 180,
    align: 'left',
    format: (row) => formatDate(row.nextRenewDate)
  }
]

const PaginationComponent = ({ page, onTablePageChange, onTablePageSizeChange }: TablePaginationProps) =>
  <>
    <Grid container>
      <Grid item xs={8} py={2}>
      </Grid>
      <Grid item xs={4}>
        <TablePagination page={page}
          onTablePageChange={onTablePageChange}
          onTablePageSizeChange={onTablePageSizeChange} />
      </Grid>
    </Grid>
  </>

const CustomerList = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { getCustomersState, tokenState } = useAppSelector((state) => state)
  const { items, pageSize, pageNumber, totalCount, searchText, selectedStatus, selectedSubscriptionPlanOption } = getCustomersState
  const loading = getCustomersState.loading === 'loading'
  const [localSearch, setLocalSearch] = useState(searchText)
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState(selectedSubscriptionPlanOption)

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    dispatch(setActiveTab(newValue))
  }

  useEffect(() => {
    if (getCustomersState.loading === 'idle') {
      dispatch(getCustomers())
    }
  }, [getCustomersState.loading, selectedStatus])

  const handlePageChange = (_event: MouseEvent<HTMLButtonElement> | null, selectedPage: number) => {
    dispatch(setPageNumber(selectedPage + 1))
  }

  const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPageSize(+event.target.value))
  }

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLocalSearch(event.target.value)
  }

  const handleResetSearch = () => {
    setLocalSearch('')
    dispatch(setSearchText(''))
  }

  const handleSearch = () => {
    dispatch(setSearchText(localSearch))
  }

  const handleSubscriptionPlanChange = useCallback((plan: SelectedSubscriptionPlanOption) => {
    setSelectedSubscriptionPlan(plan)
    dispatch(setSubscriptionPlanFilter(plan))
  }, [dispatch])

  const handleViewCustomer = (
    customer: Customer
  ) => {
    navigate(`${customer.id}`)
  }

  const page = { totalCount, size: pageSize, number: pageNumber }

  const handleExportButtonClick = () => {
    const params = new URLSearchParams()
    params.append('search', searchText)
    if (selectedSubscriptionPlanOption !== 'All') {
      params.append('subscriptionPlan', selectedSubscriptionPlanOption)
    }
    if (selectedStatus && selectedStatus !== 'all') {
      params.append('status', selectedStatus)
    }

    secureFileDownload(`${process.env.REACT_APP_API_BASE}/ui/admin/customers/export?${params.toString()}`, tokenState.token)
  }

  const subscriptionPlanDropdown = useMemo(() => (
    <TableDropdown<SelectedSubscriptionPlanOption>
      options={SUBSCRIPTION_PLAN_OPTIONS}
      value={selectedSubscriptionPlan}
      onChange={handleSubscriptionPlanChange}
      placeholder="Select Plan"
    />
  ), [selectedSubscriptionPlan, handleSubscriptionPlanChange])

  return (
    <>
      <Box p={2} sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative'
      }}>
        <Stack spacing={1.5} sx={{ paddingTop: 2, paddingBottom: 2 }}>
          <Typography variant='h3'>Customers</Typography>
          <Typography color={({ palette }) => palette.text.secondary}>Quick view of Fiable Customers and key information. Click a Customer to view more details.</Typography>
        </Stack>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={selectedStatus || 'all'} onChange={handleTabChange} aria-label="customer-tabs">
            <Tab label="All" value="all" sx={{ textTransform: 'none', width: 150 }} />
            <Tab label="Active" value="Active" sx={{ textTransform: 'none', width: 150 }} />
            <Tab label="Expired" value="Expired" sx={{ textTransform: 'none', width: 150 }} />
          </Tabs>
        </Box>

        <PaginatedTable
          columns={columns}
          items={items}
          headerComponent={<TableHeader
            searchValue={localSearch}
            onSearchTextChange={handleSearchTextChange}
            onSearchClick={handleSearch}
            onResetSearchClick={handleResetSearch}
            onExportButtonClick={handleExportButtonClick}
            subscriptionPlanElement={subscriptionPlanDropdown} />}
          footerComponent={
            <PaginationComponent
              page={page}
              onTablePageChange={handlePageChange}
              onTablePageSizeChange={handlePageSizeChange}
            />}
          onRowClick={handleViewCustomer}
        />
      </Box >
      <AnilityBackdrop open={loading} />
    </>
  )
}

export default CustomerList
