import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr'
import { createContext, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useAppSelector } from '../hooks/hooks'

interface SignalRContextType {
  hubConnection: HubConnection | null
}

const SignalRContext = createContext<SignalRContextType | null>(null)

interface SignalRContextProviderProps {
  children?: ReactNode
  url: string
}

export { HubConnectionState }
export const SignalRContextProvider = (props: SignalRContextProviderProps) => {
  const { tokenState } = useAppSelector(app => app)
  const [hubConnection, setHubConnection] = useState<HubConnection | null>(null)
  const hubConnectionRef = useRef<HubConnection | null>(null)

  const handleDisconnect = async () => {
    try {
      console.debug('Stopping SignalR connection')
      await hubConnectionRef.current?.stop()
    } catch (err) {
      console.error('Error stopping SignalR connection:', err)
    } finally {
      hubConnectionRef.current = null
    }
  }

  useEffect(() => {
    if (tokenState.token) {
      const createConnection = async () => {
        const newHubConnection = new HubConnectionBuilder()
          .withUrl(props.url, {
            accessTokenFactory: () => tokenState.token
          })
          .withAutomaticReconnect()
          .build()

        try {
          await newHubConnection.start()
          hubConnectionRef.current = newHubConnection
        } catch (err) {
          console.error('SignalR connection failed:', err)
          hubConnectionRef.current = null
        } finally {
          setHubConnection(hubConnectionRef.current)
        }
      }

      createConnection()

      return () => {
        console.debug('SignalR context is unmounting')
        if (hubConnectionRef.current) {
          handleDisconnect()
        }
      }
    }
  }, [tokenState.token])

  const contextValue = useMemo(() => ({
    hubConnection
  }), [
    hubConnection
  ])

  return <SignalRContext.Provider value={contextValue}>
    {props.children}
  </SignalRContext.Provider>
}

export const useSignalR = () => {
  const context = useContext(SignalRContext)
  if (!context) {
    throw new Error('useSignalR must be used within SignalRContextProvider')
  }
  return context
}
