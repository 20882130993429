import { Box, Chip, Popper } from '@mui/material'
import dayjs from 'dayjs'
import AdvancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import React, { useEffect, useState } from 'react'
import { formatDuration } from '../utils/functions'
dayjs.extend(AdvancedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

const getHumanizedTime = (dateTime: dayjs.Dayjs) => {
  return formatDuration(Date.now() - (dateTime.unix() * 1000))
}

interface DateTimeElapsedWithHoverProps {
  row: Record<string, any>,
  dateTimeKey: string
}

export const DateTimeElapsedWithHover = ({ row, ...props }: DateTimeElapsedWithHoverProps) => {
  const TZ = dayjs.tz.guess()
  const [popperAnchor, setPopperAnchor] = useState<null | HTMLElement>(null)
  const [humanizedTime, setHumanizedTime] = useState('')
  const [dateTimeString, setDateTimeString] = useState('')

  const handleOnMouseEnter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setPopperAnchor(event.currentTarget)
  }

  const handleOnMouseLeave = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setPopperAnchor(null)
  }

  useEffect(() => {
    const dateTime = dayjs(row[props.dateTimeKey]).tz(TZ)
    setDateTimeString(dateTime.format('YYYY/MM/DD HH:mm:ss z'))
    setHumanizedTime(getHumanizedTime(dateTime))

    const interval = setInterval(() => {
      setHumanizedTime(getHumanizedTime(dateTime))
    }, 60000)

    return () => {
      clearInterval(interval)
    }
  }, [row])

  if (row[props.dateTimeKey] == null) return null

  return <Box
    component="div"
    onMouseEnter={handleOnMouseEnter}
    onMouseLeave={handleOnMouseLeave}
    paddingY="2px"
  >
    { humanizedTime }
    <Popper
      placement="bottom"
      open={!!popperAnchor}
      anchorEl={popperAnchor}
    >
      <Chip label={dateTimeString} />
    </Popper>
  </Box>
}
